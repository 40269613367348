import React from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

class PasswordInputControl extends React.Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
    this.inputRef = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    if (this.buttonRef.current) {
      this.buttonRef.current.addEventListener('click', (_) => {
        const inputNode = this.inputRef.current;

        if (!inputNode) return;

        if (inputNode.type === 'password') {
          inputNode.setAttribute('type', 'text');
        } else {
          inputNode.setAttribute('type', 'password');
        }

        this.setState({}, () => {
          inputNode.focus();
        });
      });
    }
  }

  render() {
    const { onChange, placeholder, value, wrapperClasses } = this.props;
    const inputType = this.inputRef.current ? this.inputRef.current.type : "password";

    return <div className={`pi-control-wrapper ${wrapperClasses}`}>
      <input
        type={inputType}
        className="input-control"
        placeholder={placeholder}
        ref={this.inputRef}
        onChange={onChange}
        value={value}
      />
      <button
        className="pi-control-visibility-toggle"
        ref={this.buttonRef}
        type="button"
      >
        {
          inputType === 'password' ?
            <MdVisibility size="2em" /> : <MdVisibilityOff size="2em" />
        }
      </button>
    </div>
  }
}

export default PasswordInputControl;
