import React from 'react';
// import Center from 'react-center'
import PasswordInputControl from "./PasswordInputControl";
import DotLoader from 'react-spinners/DotLoader';
import GetUome from './GetUome';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server

        this.state = {
            loginUrl: server + 'v1/affiliate/referral/auth',
            managementPortalUrl: server + 'v1/stripe-web-payment/affiliate/referral/manage-subscription',
            login: '',
            token: '',
            password: '',
            request: 'PREFETCH',
            checkoutUrl: '',
        };
    }

    _onChangeLogin = (event) => this.setState({ login: event.target.value });

    _onChangePassword = (event) => this.setState({ password: event.target.value });
    
    _displayDownloads = (e) => {
        e.preventDefault();
        this.setState({ request: "DOWNLOADS" });
    }

    _onSubmit = (e) => {
        e.preventDefault();
        const { login, password } = this.state;

        var pattern = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        );

        if (login === '' || password === '') {
            this.setState({ request: "ERROR_NOT_SPECIFIED" })
        } else if ( !pattern.test(password)) {
            this.setState({ request: "INVALID_PASSWORD" })
        } else {
            this.setState({ request : 'PROCESSING' })
            this.authenticate({
                login,
                password
            });
        }
    }

    authenticate(user) {
        const { loginUrl } = this.state;

        let version = new Date();
        //let fDate = formatDate(version)
        let lurl = loginUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    login: user.login,
                    password: user.password
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    this.getPortalUrl(result.message.jwToken)
                } else {
                    this.setState({ request: "SERVER_ERROR" })
                }
            },

            (error) => {
                console.log(error);
                this.setState({ request: "SERVER_ERROR" })
            },
        )
    }

    getPortalUrl = (token) => {
        const { managementPortalUrl } = this.state;

        let version = new Date();
        let lurl = managementPortalUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    token
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    this.setState({
                        request: 'SUCCESS',
                        checkoutUrl: result.message,
                    })
                } else {
                    this.setState({
                        request: "SERVER_ERROR" 
                    })
                }
            },

            (error) => {
                console.log(error);
                this.setState({ 
                    isProcessing: false,
                    request: "SERVER_ERROR" 
                })
            },
        )
    }

    _redirect_Page = (url) => {
        // let tID = setTimeout(function () {
            window.location.href = url; //replace with your url
        //     window.clearTimeout(tID);// clear time out.
        // }, 1000);
    }

    render() {
        const { request, login, password, checkoutUrl } = this.state;

        if (request === 'PROCESSING') {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true}   /> 
                    </div>
                </div>
            )
        } else if (request === 'SUCCESS') {
            return (
                this._redirect_Page(checkoutUrl)
            )
        } else if (request === 'DOWNLOADS') {
            return (
                <GetUome />
            )
        } else {
            return (
                <div className="container">
                    <form className="password-form pure-form">
                        <div className="spacer">
                            <h3 className="header3">Login</h3>
                            <div className="input-control-wrapper">
                                <input
                                    type="email"
                                    id="email"
                                    className="input-control"
                                    value={login}
                                    placeholder="Email address"
                                    onChange={this._onChangeLogin}
                                />
                            </div>
                            <PasswordInputControl
                                wrapperClasses="input-control-wrapper password"
                                placeholder="Password"
                                value={password}
                                onChange={this._onChangePassword}
                            />

                            {request === "INVALID_PASSWORD" &&
                                <p className="errorMessage left">Please check your details and try again</p>
                            }

                            {request === "ERROR_NOT_SPECIFIED" &&
                                <p className="errorMessage left">Please enter your login and password</p>
                            }

                            {request === "SERVER_ERROR" &&
                                <p className="errorMessage left">Something went wrong, please try again</p>
                            }
                        </div>
                        <div className="input-control-submit-wrapper">
                            <button type="button" className="button-full-width" onClick={this._onSubmit}>Login</button>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default LoginForm;