import React from 'react';
import { MdPanoramaFishEye, MdCheckCircle, MdInfo, MdNoEncryption } from 'react-icons/md';
import AffiliatePaymentInfo from './AffiliatePaymentInfo';
import DotLoader from 'react-spinners/DotLoader';

class YourPlanForm extends React.Component {
  constructor(props) {
    super(props);
    let server = this.props.server,
        stripeKey = this.props.stripeKey,
        token = this.props.token;

    this.state = {
      validateEmailUrl: server + 'v1/affiliate/referral/validate',
      affiliateInfoUrl: server + 'v1/affiliate/info',
      token,
      isMonthly : false,
      isYearly : false,
      isReadyForPayment: false,
      isEmailValidated: false,
      server,
      stripeKey,
      request: '',
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.validateEmail();
  }

  validateEmail = () => {
    const { token, validateEmailUrl } = this.state;

    let version = new Date();
    let lurl = validateEmailUrl;
    lurl += '?version=' + version;

    fetch(lurl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: { token }
        })
    })
    .then(res => res.json())
    .then(
        (result) => {
            if (result.success === 'OK') {
                if (result.code === 'TOKEN_EXPIRED') {
                    this.setState({
                        isEmailValidated: true,
                        request: "TOKEN_EXPIRED"
                    })
                } else {
                    this.setState({
                        isEmailValidated: true,
                    })
                }
            } else {
                this.setState({
                    isEmailValidated: true,
                    request: "SERVER_ERROR",
                    errorMessage: result.message
                })
            }
        },

        (error) => {
            console.log(error);
            this.setState({
                isEmailValidated: true,
                request: "SERVER_ERROR",
                errorMessage: error || null
            })
        },
    )
}

  _onMonthlyClick = (e) =>
    this.setState({
      isMonthly: !this.isMonthly,
      isYearly:  this.isMonthly ? false : this.isYearly
    })
  
  _onAnnualClick = (e) => 
    this.setState({
      isYearly : !this.isYearly,
      isMonthly:  this.isYearly ? false : this.isMonthly
    })

  _onSubmit = (e) => {
    const { isMonthly, isYearly } = this.state;

    if ( !isMonthly && !isYearly ) {
      this.setState({ request: "ERROR_NOT_SPECIFIED" })
    }

    if ( isMonthly || isYearly ) {
      this.setState({
        isReadyForPayment: true
      })
    }
  }
    

  render() {
    const { isMonthly, isYearly, isReadyForPayment, isEmailValidated, server, 
            request, token, stripeKey, errorMessage } = this.state;

    if (!isEmailValidated ) {
      return (
          <div className="container">
              <div className="innerSpacer spinnerCenter">
                  <DotLoader color='#008AFF' loading={true}/> 
              </div>
          </div>
      )
    } else if (request === 'SERVER_ERROR') {
      return (
          <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Something went wrong</h5>
                            <MdInfo size="10em" color='#FF5252' />
                        </div>
                        {errorMessage !== "" ?
                            <p className="something-wrong-description center">{errorMessage}</p>
                            :
                            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
      )
     } else if (request === 'TOKEN_EXPIRED') {
      return (
          <div className="container">
            <div className="wrap innerSpacer center">
              <h5 className="header3 center">Link expired</h5>
              <MdNoEncryption size="10em" color='#008AFF' />
            </div>
              <p className="token-expired-description center">This validate email link has expired, a new one has been sent to your email.</p>
              <p className="token-expired-description center">Please check your inbox and click on the link.</p>
          </div>
      )
  } else if ( isReadyForPayment ) {
      const plan = isYearly ?  'annual' : 'monthly'
      return (
        AffiliatePaymentInfo({
          server,
          plan, 
          token,
          stripeKey,
        })
      )    
    } else {
        return (
          <div className="container">
            <form className="pure-form address-form">
              <h3 className="header3-left">Your plan</h3>
                <p className="description left"> 
                Select the right plan for you. Get the tools you need to run your business. Choose from one of the options below.
                </p>
              <div>
                  <div className='grey'>
                    <h4 className="button-header">Simple Monthly</h4>
                    <text className="button-price"> 
                        <text className="bold">£9.99&nbsp;</text>
                        /monthly
                    </text>
                  <button
                    className="manage_plan_buttons"
                    onClick={this._onMonthlyClick}
                    type="button"
                  >
                    {
                      isMonthly 
                      ? <MdCheckCircle size="2em" color='#008AFF'/> 
                      : <MdPanoramaFishEye size="2em" color='#A1A5AC'/>
                    }
                  </button>
                </div>
                <div className='affiliateSpacer'></div>
                <div className='affiliateSpacer'></div>
                <div className='grey'>
                  <h4 className="button-header">Simple Annual</h4>
                  <text className="button-price"> 
                      <text className="bold">£99.99&nbsp;</text>
                      /yearly
                  </text>
                  <button
                    className="manage_plan_buttons"
                    onClick={this._onAnnualClick}
                    type="button"
                  >
                    {
                      isYearly 
                      ? <MdCheckCircle size="2em" color='#008AFF' /> 
                      : <MdPanoramaFishEye size="2em" color='#A1A5AC' />
                    }
                  </button>
                </div>
                <div className='affiliateSpacer'></div>
                <div className='affiliateSpacer'></div>
                <p className="description-grey left"> 
                The complete stack for running a business. Instantly receive 100% of your money with open banking payments - avoid heft card transaction fees. Manage your clients, track expenses, submit VAT reports directly to HMRC...&nbsp;
                <a href="https://www.myuome.com/pricing" target="_blank" rel="noopener noreferrer">Learn more</a>
                </p>
                {request === "ERROR_NOT_SPECIFIED" &&
                    <p className="errorMessage left">Please select a plan</p>
                }
                <div className='affiliateSpacer'></div>
                <div className="input-control-submit-wrapper">
                    <button type="button" className="button-full-width" onClick={this._onSubmit}>Continue</button>
                </div>
              </div>
            </form>
          </div>
        )
      }
  }
}

export default YourPlanForm;
