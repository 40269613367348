import React from 'react';
// import Center from 'react-center'
import { MdNoEncryption, MdInfo } from 'react-icons/md';
import DotLoader from 'react-spinners/DotLoader';
import GetUome from './GetUome';

class KlarnaCheckout extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server,
            token = this.props.token;

        this.state = {
            getKlarnaCheckoutUrl: server + 'v1/stripe-web-payment/klarna',
            token,
            checkoutUrl: '',
            errorMessage: '',
            request: 'PREFETCH',
            isUrlLoaded : false
        };
    }

    componentDidMount() {
        this.getCheckoutUrl();
    }

    _displayDownloads = (e) => {
        e.preventDefault();
        this.setState({ request: "DOWNLOADS" });
    }


    _redirect_Page = (url) => {
        let tID = setTimeout(function () {
            window.location.href = 
            url; //replace with your url
            window.clearTimeout(tID);// clear time out.
        }, 1000);
    }


    getCheckoutUrl = () => {
        const { token, getKlarnaCheckoutUrl } = this.state;

        let version = new Date();
        let lurl = getKlarnaCheckoutUrl;
        lurl += '?version=' + version;

        const requestData = {
            user: { token }
        };

        if (this.props.amount) {
            requestData.user.amount = this.props.amount;
        }

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData)
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        this.setState({
                            isUrlLoaded: true,
                            request: "TOKEN_EXPIRED"
                        })
                    } else {
                        let response = result.message
                        this.setState({
                            request: "SUCCESS",
                            isUrlLoaded: true,
                            checkoutUrl: response,
                        })
                    }
                } else {
                    this.setState({
                        isUrlLoaded: true,
                        request: "SERVER_ERROR",
                        errorMessage: result.message
                    })
                }
            },

            (error) => {
                console.log(error);
                this.setState({
                    isUrlLoaded: true,
                    request: "SERVER_ERROR"
                })
            },
        )
    }

    render() {
        const { request, isUrlLoaded, checkoutUrl, errorMessage } = this.state;

        if (!isUrlLoaded) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true} />
                    </div>
                </div>
            )
        } else if (request === 'SUCCESS') {
            return (
                this._redirect_Page(checkoutUrl)
            )
        } else if (request === 'DOWNLOADS') {
            return (
                <GetUome />
            )
        } else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Link expired</h5>
                            <MdNoEncryption size="10em" color='#008AFF' />
                        </div>
                        <p className="token-expired-description center">This payment link has expired, a new one has been sent to your email.</p>
                        <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                        <GetUome />
                </div>
            )
        } else {
            return (
                <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Something went wrong</h5>
                            <MdInfo size="10em" color='#FF5252' />
                        </div>
                        {errorMessage !== "" ?
                            <p className="something-wrong-description center">{errorMessage}</p>
                            :
                            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
            )
        }
    }
}

export default KlarnaCheckout;