import React from "react";
import fullNameLogo from "../images/uome-logo-white.svg";

const Footer = () => (
    <div className="footer">
        <div className="center">
            <img className="footer-logo" src={fullNameLogo} alt="Uome" />
        </div>
        {/* <p className="footer-description center">
            © Uome Ltd. 48 Cheyneys Avenue, Edgware, Middlesex, England, HA8 6SF. Registered in England & Wales, No: 13961155.
        </p> */}
    </div>
);

export default Footer;