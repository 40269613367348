import React from 'react';
import KlarnaLogo from "../images/klarna-logo.svg";
import { MdInfo, MdNoEncryption } from 'react-icons/md';
import CardCheckout from './CardCheckout';
import KlarnaCheckout from './KlarnaCheckout';
import PlaidCheckout from './PlaidCheckout';
import DotLoader from 'react-spinners/DotLoader';

class PointOfSaleUniversalCheckout extends React.Component {
  constructor(props) {
    super(props);
    let server = this.props.server,
        token = this.props.token;

    this.state = {
      paymentOptionsUrl: server + 'v1/web/payment/options',
      token,
      isPaymentOptionsLoaded: false,
      server,
      request: '',
      errorMessage: '',
      name: null,
      company: null,
      isPlaidEnabled: false,
      isStripeEnabled: false,
      isKlarnaEnabled: false,
      isCardPaymentClicked: false,
      isKlarnaPaymentClicked: false,
      isPlaidPaymentClicked: false,
      reference: null,
      date: null,
      amount: null,
      currency: null,
      vat: null,
      salesTax: null,
      items: null,
    };
  }

  componentDidMount() {
    this.paymentOptionRetrieve();
  }

  paymentOptionRetrieve = () => {
    const { token, paymentOptionsUrl } = this.state;

    let version = new Date();
    let lurl = paymentOptionsUrl;
    lurl += '?version=' + version;

    fetch(lurl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: { token }
        })
    })
    .then(res => res.json())
    .then(
        (result) => {
            if (result.success === 'OK') {
                if (result.code === 'TOKEN_EXPIRED') {
                    this.setState({
                        isPaymentOptionsLoaded: true,
                        request: "TOKEN_EXPIRED"
                    })
                } else {
                    let response = result.message
                    this.setState({
                        isPaymentOptionsLoaded: true,
                        name: response.name,
                        company: response.company,
                        isPlaidEnabled: response.plaidEnabled,
                        isStripeEnabled: response.stripePaymentsEnabled,
                        isKlarnaEnabled: response.klarnaEnabled,
                        reference: response.reference,
                        date: response.date,
                        items: response.items,
                        amount: response.amount,
                        currency: response.currency,
                        vat: response.vat,
                        salesTax: response.salesTax
                    })
                }
            } else {
                this.setState({
                    isPaymentOptionsLoaded: true,
                    request: "SERVER_ERROR",
                    errorMessage: result.message
                })
            }
        },

        (error) => {
            console.log(error);
            this.setState({
                isPaymentOptionsLoaded: true,
                request: "SERVER_ERROR",
                errorMessage: error || null
            })
        },
    )
}

_onPlaidPaymentClick = (e) => this.setState({ isPlaidPaymentClicked: true })
  
_onKlarnaPaymentClick = (e) => this.setState({ isKlarnaPaymentClicked: true })

_onCardPaymentClick = (e) => this.setState({ isCardPaymentClicked: true })

_displayItem = ({ description, qty, price }) => (
    <div>
        <text className='items-description items-padding'>{description}</text>
        <text className='items-description items-padding'>x {qty}</text>
        <text className="bold">{(qty*price).toFixed(2)}</text>
    </div>
); 

_currencySymbol (currency) {
    const currencySymbols = {
        GBP: '£',
        USD: '$',
        EUR: '€'
    };

    return currencySymbols[currency] || (currency + ' ');  // Default to the currency code if not found
}

render() {
    const { name, company, isPlaidEnabled, isKlarnaEnabled, isStripeEnabled, isPaymentOptionsLoaded, server, 
            request, token, reference, date, amount, currency, vat, salesTax, items, errorMessage,
            isCardPaymentClicked, isPlaidPaymentClicked, isKlarnaPaymentClicked } = this.state;

    if (!isPaymentOptionsLoaded ) {
      return (
          <div className="container">
              <div className="innerSpacer spinnerCenter">
                  <DotLoader color='#008AFF' loading={true}/> 
              </div>
          </div>
      )
    } 
    else if (request === 'SERVER_ERROR') {
      return (
          <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Something went wrong</h5>
                            <MdInfo size="10em" color='#FF5252' />
                        </div>
                        {errorMessage !== '' ?
                            <p className="something-wrong-description center">{errorMessage}</p>
                            :
                            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
      )
    } 
    else if (request === 'TOKEN_EXPIRED') {
      return (
        <div className="container">
        <div className="wrap innerSpacer center">
            <h5 className="header3 center">Link expired</h5>
            <MdNoEncryption size="10em" color='#008AFF' />
        </div>
        <p className="token-expired-description center">This payment link has expired, a new one has been sent to your email.</p>
        <p className="token-expired-description center">Please check your inbox and click on the link.</p>
</div>
      )
      
    } else if ( isCardPaymentClicked ) {
        return (
            <CardCheckout
                server={server}
                token={token}
            />
        )    
    } else if ( isKlarnaPaymentClicked ) {
        return (
            <KlarnaCheckout
                server={server}
                token={token}
            />
        )    
    } else if ( isPlaidPaymentClicked ) {
        return (
            <PlaidCheckout
                server={server}
                token={token}
            />
        )    
      } else {
        const subTotal = amount - (vat || salesTax);
        return (
          <div className="container">
            <form className="pure-form address-form">
              <h3 className="header3-left">Pay {this._currencySymbol(currency)}{amount.toFixed(2)}</h3>
              <p className="pay-to-description left"> 
                    <text>to </text>
                    {company || name}
                </p>
              <div>
                {
                    isPlaidEnabled && 
                    <button type="button" className="button-payment-option" 
                    onClick={this._onPlaidPaymentClick}>Pay by Bank</button>
                }
                {
                    isStripeEnabled && 
                    <button type="button" className="button-payment-option" 
                    onClick={this._onCardPaymentClick}>Card Payment</button>
                }
                {
                    isKlarnaEnabled && 
                    <button type="button" className="klarna-payment-option" onClick={this._onKlarnaPaymentClick} >
                        <img src={KlarnaLogo} alt="klarna logo" width="90px"/>
                    </button>
                }
                <hr></hr>
                <div className='payment-option-spacer '></div>
                <h3 className="header3-left">Summary</h3>
                <p className="order-description left"> 
                    <text className="bold">Ref: </text>
                    {reference}
                </p>
                <p className="order-description left"> 
                    <text className="bold">Date: </text>
                    {date}
                </p> 
                <div className='payment-option-spacer '></div>
                <p className="items-description left">
                    {items.map((item) => (
                        <this._displayItem
                            description={item.name}
                            qty={item.qty}
                            price={Number(item.price)}
                        />
                    ))}
                </p>
                <div className='payment-option-spacer '></div>
                <p className="sub-total-description left"> 
                    {(vat > 0 || salesTax > 0) ? (
                        <>
                            <span>Subtotal: </span>
                            {subTotal.toFixed(2)}
                        </>
                    ) : null}
                </p>
                <p className="sub-total-description left"> 
                    {vat > 0 ? (
                        <>
                            <span>VAT: </span>
                            {vat.toFixed(2)}
                        </>
                    ) : null}
                </p>
                <p className="sub-total-description left"> 
                    {salesTax > 0 ? (
                        <>
                            <span>Sales tax: </span>
                            {salesTax.toFixed(2)}
                        </>
                    ) : null}
                </p>
                <div className='payment-option-spacer '></div>
                <p className="total-description left"> 
                    <text>Total: </text>
                    {this._currencySymbol(currency)}{amount.toFixed(2)}
                </p>
              </div>
            </form>
          </div>
        )
      }
  }
}

export default PointOfSaleUniversalCheckout;
