import React from "react";
import AppStoreIcon from "../images/btn-app-store.png";
import PlayStoreIcon from "../images/btn-google-play.png";

const GetUome = () => (
  <div className="get-uome">
    <h4 className="get-uome-header center">Get Uome</h4>
        <div className="downloadSpacer center">
            <a className="get-uome-app-icon-spacer" href="https://apps.apple.com/gb/app/uome/id1608161556" target="_blank" rel="noopener noreferrer">
            <img src={AppStoreIcon} alt="AppStore" width="160" />
            </a>
            &nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.myuome.app" target="_blank" rel="noopener noreferrer">
            <img src={PlayStoreIcon} alt="PlayStore" width="160" />
            </a>
        </div>
        <p className="get-uome-description center">
            Keep updated on the go! Download the Uome app to communicate and make payments from anywhere.
        </p>
    </div>
);

export default GetUome;