import React from 'react';
// import Center from 'react-center'
import { MdCheckCircle, MdNoEncryption } from 'react-icons/md';
import DefaultProfile from "../images/default-avatar.png";
import DotLoader from 'react-spinners/DotLoader';
import GetUome from './GetUome'
import { CountryDropdown } from 'react-country-region-selector';

class ChangeAddressForm extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server,
            token = this.props.token;

        this.state = {
            postAddressUrl: server + 'v1/web/address/request/update',
            getAvatarUrl: server + 'v1/web/address/request/info',
            token,
            company: '',
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            postCode: '',
            country : '',
            tel: '',
            request: 'PREFETCH',
            sender: '',
            avatar: '',
            isAvatarLoaded: false
        };
    }

    componentDidMount() {
        this.getAvatar();
    }

    _onChangeFirstName = (event) => this.setState({ firstName: event.target.value });

    _onChangeLastName = (event) => this.setState({ lastName: event.target.value });

    _onChangeCompany = (event) => this.setState({ company: event.target.value });

    _onChangeAddressLine1 = (event) => this.setState({ addressLine1: event.target.value });

    _onChangeAddressLine2 = (event) => this.setState({ addressLine2: event.target.value });

    _onChangeCity = (event) => this.setState({ city: event.target.value });

    _onChangePostCode = (event) => this.setState({ postCode: event.target.value });

    _onChangeTelephone = (event) => this.setState({ tel: event.target.value });

    _selectCountry = (val) => this.setState({ country : val })

    _displayDownloads = (e) => {
        e.preventDefault();
        this.setState({ request: "DOWNLOADS" });
    }

    _onSubmitPasswordChange = (e) => {
        e.preventDefault();
        const { addressLine1, addressLine2, city, postCode, 
                tel, country, firstName, lastName, company } = this.state;

        if ( addressLine1 === '' || city === '' || 
            postCode === '' || country === '' ) {
            this.setState({ request: "ERROR_NOT_SPECIFIED" })
        } else {
            this.postAddress({
                firstName,
                lastName,
                company,
                addressLine1,
                addressLine2,
                city,
                postCode,
                tel,
                country
            });
        }
    }

    getAvatar = () => {
        const { token, getAvatarUrl } = this.state;

        let version = new Date();
        let lurl = getAvatarUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: { token }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'OK') {
                        if (result.code === 'TOKEN_EXPIRED') {
                            this.setState({
                                isAvatarLoaded: true,
                                request: "TOKEN_EXPIRED"
                            })
                        } else {
                            let response = result.message
                            this.setState({
                                isAvatarLoaded: true,
                                sender: response.company || response.name,
                                avatar: response.avatar,
                                company: response.clientCompany || "",
                                firstName: response.clientFirstName || "",
                                lastName: response.clientLastName || "",
                                addressLine1: response.clientAddressLine1 || "",
                                addressLine2: response.clientAddressLine2 || "",
                                city: response.clientCity || "",
                                postCode: response.clientPostCode || "",
                                tel: response.clientTel || "",
                                country: response.clientCountry || "",
                                countryCode: response.clientCountryCode || ""
                            })
                        }
                    } else {
                        this.setState({
                            isAvatarLoaded: true,
                            request: "SERVER_ERROR"
                        })
                    }
                },

                (error) => {
                    console.log(error);
                    this.setState({
                        isAvatarLoaded: true,
                        request: "SERVER_ERROR"
                    })
                },
            )
    }

    postAddress(address) {
        const { postAddressUrl, token } = this.state;

        let version = new Date();
        let lurl = postAddressUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    token,
                    firstName: address.firstName,
                    lastName: address.lastName,
                    company : address.company,
                    addressLine1: address.addressLine1,
                    addressLine2: address.addressLine2,
                    city: address.city,
                    postCode: address.postCode,
                    tel: address.tel,
                    country: address.country,
                    countryCode: address.countryCode
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        this.setState({ request: "TOKEN_EXPIRED" })
                    } else {
                        this.setState({ request: "SUCCESS" })
                    }
                } else {
                    this.setState({ request: "SERVER_ERROR" })
                }
            },

            (error) => {
                console.log(error);
                this.setState({ request: "SERVER_ERROR" })
            },
        )
    }

    render() {

        const { request, firstName, lastName, company, addressLine1, addressLine2, 
                city, postCode, tel, countryCode, isAvatarLoaded, sender, avatar } = this.state;

        if (!isAvatarLoaded) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true}/> 
                    </div>
                </div>
            )
        } else if (request === 'SUCCESS') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Address details confirmed</h5>
                        <MdCheckCircle size="10em" color='#008AFF' />
                    </div>
                    <p className="description center">
                        Keep an eye out for the invoice. It’ll be with you soon!
                    </p>
                    <GetUome />
                </div>
            )
        } else if (request === 'DOWNLOADS') {
            return (
                <GetUome />
            )
        } else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Link expired</h5>
                        <MdNoEncryption size="10em" color='#008AFF' />
                    </div>
                    <p className="token-expired-description center">This address link has expired, a new one has been sent to your email.</p>
                    <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                    <GetUome />
                </div>
            )
        } else {
            return (
                <div className="container">
                    <form className="pure-form address-form">
                        <div className="spacer">
                            <h3 className="header3 left">Address request</h3>
                            <div className="address-form-avatar">
                                {(avatar === '' || avatar === null) ?
                                    <img className="circle-avatar" src={DefaultProfile} alt="Avatar" width="100" height="100" border-radius="50%" />
                                    :
                                    <img className="circle-avatar" src={avatar} alt="Avatar" width="100" height="100" border-radius="50%"/>
                                }
                            </div>
                            <p className="description left"><text className="bold">{sender} </text>needs your details for an invoice. Please complete the information below</p>
                            <hr></hr>
                            <div className="spacer"></div>
                            <div class="wrap-1">
                                <label className="input-title" for="PhoneNumber">Your details</label>
                                <div className="input-control-row">
                                    <div className="input-control-wrapper">
                                        <input
                                            type="firstName"
                                            className="input-control"
                                            value={firstName}
                                            placeholder="First name*"
                                            style={{textTransform:"capitalize"}}
                                            maxLength={70}
                                            onChange={this._onChangeFirstName}
                                        />
                                    </div>
                                    <div className="input-control-wrapper">
                                        <input
                                            type="lastName"
                                            className="input-control"
                                            value={lastName}
                                            placeholder="Last name*"
                                            style={{textTransform:"capitalize"}}
                                            maxLength={70}
                                            onChange={this._onChangeLastName}
                                        />
                                    </div>
                                </div>
                                <div className="input-control-wrapper">
                                    <input
                                        type="tel"
                                        id="PhoneNumber"
                                        className="input-control"
                                        value={tel}
                                        placeholder="Mobile number"
                                        maxLength={20}
                                        onChange={this._onChangeTelephone}
                                    />
                                </div>
                                <div className="input-control-wrapper">
                                    <input
                                        type="text"
                                        id="Company"
                                        className="input-control inputText"
                                        value={company}
                                        placeholder="Company name"
                                        maxLength={40}
                                        style={{textTransform:"capitalize"}}
                                        onChange={this._onChangeCompany}
                                    />
                                </div>
                            </div>
                            <div className='affiliateSpacer'></div>
                            <label className="input-title" for="AddressLine1">Billing address</label>
                            <div className="input-control-wrapper">
                                <input
                                    type="text"
                                    id="AddressLine1"
                                    className="input-control inputText"
                                    value={addressLine1}
                                    placeholder="Address Line 1*"
                                    maxLength={40}
                                    style={{textTransform:"capitalize"}}
                                    onChange={this._onChangeAddressLine1}
                                />
                            </div>
                            <div className="input-control-wrapper">
                                <input
                                    type="text"
                                    className="input-control inputText"
                                    value={addressLine2}
                                    placeholder="Address Line 2"
                                    maxLength={40}
                                    style={{textTransform:"capitalize"}}
                                    onChange={this._onChangeAddressLine2}
                                />
                            </div>
                            <div className="input-control-row">
                                <div className="input-control-wrapper">
                                    <input
                                        type="city"
                                        className="input-control"
                                        value={city}
                                        placeholder="City*"
                                        style={{textTransform:"capitalize"}}
                                        maxLength={40}
                                        onChange={this._onChangeCity}
                                    />
                                </div>
                                <div className="input-control-wrapper">
                                    <input
                                        type="postCode"
                                        className="input-control"
                                        value={postCode}
                                        placeholder="Postal*"
                                        style={{textTransform:"uppercase"}}
                                        maxLength={10}
                                        onChange={this._onChangePostCode}
                                    />
                                </div>
                            </div>
                            <div className="input-control-wrapper">
                                <CountryDropdown
                                    value={countryCode}
                                    className="input-control"
                                    valueType='short'
                                    priorityOptions={["GB", "US"]}
                                    whitelist={["GB", "US"]}
                                    disabled
                                    onChange={(_) => {}} />
                            </div>
                            {request === "ERROR_NOT_SPECIFIED" &&
                                <p className="errorMessage left">Please enter all required fields e.g. name, address line 1, city, postcode and country</p>
                            }
                            {request === "SERVER_ERROR" &&
                                <p className="errorMessage left">Something went wrong, please try again</p>
                            }
                        </div>
                        <div className="input-control-submit-wrapper">
                            <button type="button" className="button-full-width" onClick={this._onSubmitPasswordChange}>Done</button>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default ChangeAddressForm;