import React from 'react';
// import Center from 'react-center'
import { MdCheckCircle, MdNoEncryption, MdInfo } from 'react-icons/md';
import DotLoader from 'react-spinners/DotLoader';
import GetUome from './GetUome';


class EmailVerifiedForm extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server,
            token = this.props.token;

        this.state = {
            validateEmailUrl: server + 'v1/signup/validate',
            token,
            requestStatus: 'PREFETCH',
            isRequestCompleted: false,
            errorMessage: ""
        };
    }

    componentDidMount() {
        this.validateEmail();
    }


    validateEmail = () => {
        const { token, validateEmailUrl } = this.state;

        let version = new Date();
        let lurl = validateEmailUrl;
        lurl += '?version=' + version;

        //alert(token);
        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: { token }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'OK') {
                        if (result.code === 'TOKEN_EXPIRED') {
                            this.setState({
                                requestStatus: "TOKEN_EXPIRED",
                                isRequestCompleted: true
                            })
                        } else {
                            this.setState({
                                requestStatus: "SUCCESS",
                                isRequestCompleted: true
                            })
                        }
                    } else {
                        this.setState({
                            requestStatus: "SERVER_ERROR",
                            isRequestCompleted: true,
                            errorMessage: result.message
                        })
                    }
                },

                (error) => {
                    console.log(error);
                    this.setState({
                        requestStatus: "SERVER_ERROR",
                        isRequestCompleted: true,
                        errorMessage: error
                    })
                },
            )
    }

    render() {

        const { requestStatus, isRequestCompleted, errorMessage } = this.state;

        if (!isRequestCompleted) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true}/> 
                    </div>
                </div>
            )
        } else if (requestStatus === 'SUCCESS') {
            return (
                <div className="container">
                <div className="wrap innerSpacer center">
                    <h5 className="header3 center">Email verified</h5>
                    <MdCheckCircle size="10em" color='#008AFF' />
                </div>
                <p className="description center">
                    Thanks for that, you can now go back to the Uome app and log in to continue.
                </p>
                <GetUome />
            </div>
            )
        } else if (requestStatus === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Link expired</h5>
                        <MdNoEncryption size="10em" color='#008AFF' />
                    </div>
                    <p className="token-expired-description center">This verification link has expired, a new one has been sent to your email.</p>
                    <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                </div>
            )
        } else {
            return (
                 <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Something went wrong</h5>
                            <MdInfo size="10em" color='#FF5252' />
                        </div>
                        {errorMessage !== "" ?
                            <p className="something-wrong-description center">{errorMessage}</p>
                            :
                            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
            )
        }
    }
}

export default EmailVerifiedForm;