import React from "react";
import fullNameLogo from "../images/uome-logo.png";


const Header = () => (
    <div className="logo-header">
        <img src={fullNameLogo} alt="Uome" width="65" />
    </div>
);

export default Header;