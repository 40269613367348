import React from 'react';
// import Center from 'react-center'
import { MdCheckCircle, MdNoEncryption } from 'react-icons/md';
import PasswordInputControl from "./PasswordInputControl";
import GetUome from './GetUome'

class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server,
            token = this.props.token;

        this.state = {
            resetPasswordUrl: server + 'v1/password/change',
            token,
            newPassword1: '',
            newPassword2: '',
            request: 'PREFETCH'
        };
    }

    _onChangeNewPassword1 = (event) => {
        let newPassword1 = event.target.value
        this.setState({
            newPassword1,
            request: 'PREFETCH'
        });
    }

    _onChangeNewPassword2 = (event) => {
        let newPassword2 = event.target.value
        this.setState({ newPassword2 });
    }

    _onSubmitPasswordChange = (e) => {
        e.preventDefault();
        const { login, newPassword1, newPassword2 } = this.state;

        var pattern = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        );

        if (newPassword1 === '' || newPassword2 === '') {
            this.setState({ request: "ERROR_NOT_SPECIFIED" })
        } else if (newPassword1 !== newPassword2) {
            this.setState({ request: "ERROR_MISMATCH_PASSWORDS" })
        } else if ( !pattern.test(newPassword1)) {
            this.setState({ request: "INVALID_PASSWORD" })
        } else {
            this.resetPassword({
                login,
                password: newPassword1
            });
        }
    }

    resetPassword(user) {
        const { resetPasswordUrl, token } = this.state;

        let version = new Date();
        //let fDate = formatDate(version)
        let lurl = resetPasswordUrl;
        lurl += '?version=' + version;

        //alert(token);
        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    password: user.password,
                    token
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'OK') {
                        if (result.message.includes("link has been mailed to")) {
                            this.setState({ request: "TOKEN_EXPIRED" })
                        } else {
                            this.setState({ request: "SUCCESS" })
                        }
                    } else {
                        this.setState({ request: "SERVER_ERROR" })
                    }
                },

                (error) => {
                    console.log(error);
                    this.setState({ request: "SERVER_ERROR" })
                },
            )
    }

    render() {

        const { request, newPassword1, newPassword2 } = this.state;

        if (request === 'SUCCESS') {
            return (
                <div className="container">
                <div className="wrap innerSpacer center">
                    <h5 className="header3 center">Password set</h5>
                    <MdCheckCircle size="10em" color='#008AFF' />
                </div>
                <p className="description center">
                    Please use your new password to login to the Uome app.
                </p>
                <GetUome />
            </div>
            )
        } else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Link expired</h5>
                        <MdNoEncryption size="10em" color='#008AFF' />
                    </div>
                    <p className="token-expired-description center">This password link has expired, a new one has been sent to your email.</p>
                    <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                </div>
            )
        } else {
            return (
                <div className="container">
                    <form className="password-form pure-form">
                        <div className="spacer">
                            <h3 className="header3-left">Set password</h3>
                            <p className="description left">Set the new password for your account so that you can login.
                            </p>
                            <PasswordInputControl
                                wrapperClasses="input-control-wrapper password"
                                placeholder="Password"
                                value={newPassword1}
                                onChange={this._onChangeNewPassword1}
                            />

                            <PasswordInputControl
                                wrapperClasses="input-control-wrapper password-confirm"
                                placeholder="Confirm Password"
                                value={newPassword2}
                                onChange={this._onChangeNewPassword2}
                            />

                            {request === "INVALID_PASSWORD" &&
                                <p className="errorMessage left">Please enter a strong password.<br/> Must have 8 characters minimum with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                            }

                            {request === "ERROR_NOT_SPECIFIED" &&
                                <p className="errorMessage left">One or both passwords has not been entered</p>
                            }

                            {request === "ERROR_MISMATCH_PASSWORDS" &&
                                <p className="errorMessage left">Entered passwords do not match</p>
                            }

                            {request === "SERVER_ERROR" &&
                                <p className="errorMessage left">Something went wrong, please try again</p>
                            }
                        </div>
                        <div className="input-control-submit-wrapper">
                            <button type="button" className="button-full-width" onClick={this._onSubmitPasswordChange}>Set password</button>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default ForgotPasswordForm;