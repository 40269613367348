import React from 'react';
import KlarnaLogo from "../images/klarna-logo.svg";
import { MdInfo, MdNoEncryption } from 'react-icons/md';
import CardCheckout from './CardCheckout';
import KlarnaCheckout from './KlarnaCheckout';
import PlaidCheckout from './PlaidCheckout';
import DotLoader from 'react-spinners/DotLoader';


class UniversalCheckout extends React.Component {
    constructor(props) {
        super(props);
        let server = this.props.server,
            token = this.props.token;

        this.state = {
            paymentOptionsUrl: server + 'v1/web/payment/options',
            token,
            isPaymentOptionsLoaded: false,
            server,
            request: '',
            errorMessage: '',
            name: null,
            company: null,
            isPlaidEnabled: false,
            isStripeEnabled: false,
            isKlarnaEnabled: false,
            isCardPaymentClicked: false,
            isKlarnaPaymentClicked: false,
            isPlaidPaymentClicked: false,
            reference: null,
            date: null,
            amount: null,
            currency: null,
            vat: null,
            salesTax: null,
            items: null,
            partialPaymentsEnabled: null,
            tipAmount: '',
        };
    }

    componentDidMount() {
        this.paymentOptionRetrieve();
    }

    paymentOptionRetrieve = () => {
        const { token, paymentOptionsUrl } = this.state;

        let version = new Date();
        let lurl = paymentOptionsUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: { token }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'OK') {
                        if (result.code === 'TOKEN_EXPIRED') {
                            this.setState({
                                isPaymentOptionsLoaded: true,
                                request: "TOKEN_EXPIRED"
                            })
                        } else {
                            let response = result.message
                            this.setState({
                                isPaymentOptionsLoaded: true,
                                name: response.name,
                                company: response.company,
                                isPlaidEnabled: response.plaidEnabled,
                                isStripeEnabled: response.stripePaymentsEnabled,
                                isKlarnaEnabled: response.klarnaEnabled,
                                reference: response.reference,
                                date: response.date,
                                items: response.items,
                                amount: response.amount,
                                currency: response.currency,
                                vat: response.vat,
                                salesTax: response.salesTax,
                                partialPaymentsEnabled: response.partialPaymentsEnabled,
                            })
                        }
                    } else {
                        this.setState({
                            isPaymentOptionsLoaded: true,
                            request: "SERVER_ERROR",
                            errorMessage: result.message
                        })
                    }
                },

                (error) => {
                    console.log(error);
                    this.setState({
                        isPaymentOptionsLoaded: true,
                        request: "SERVER_ERROR",
                        errorMessage: error || null
                    })
                },
            )
    }

    _onPlaidPaymentClick = (e) => this.setState({ isPlaidPaymentClicked: true })

    _onKlarnaPaymentClick = (e) => this.setState({ isKlarnaPaymentClicked: true })

    _onCardPaymentClick = (e) => this.setState({ isCardPaymentClicked: true })

    _displayItem = ({ description, qty, price }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <text className='items-description'>{qty} x {description}</text>
            </div>
            <div style={{ textAlign: 'right', paddingLeft: '15px' }}>
                <text className='items-description'>{(qty * price).toFixed(2)}</text>
            </div>
        </div>
    );

    _currencySymbol(currency) {
        const currencySymbols = {
            GBP: '£',
            USD: '$',
            EUR: '€'
        };

        return currencySymbols[currency] || (currency + ' ');  // Default to the currency code if not found
    }

    handleAmountChange = (event) => {
        const value = event.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            this.setState({
                tipAmount: value
            });
        }
    }

    render() {
        const { name, company, isPlaidEnabled, isKlarnaEnabled, isStripeEnabled, isPaymentOptionsLoaded, server,
            request, token, reference, date, amount, currency, vat, salesTax, items, errorMessage,
            isCardPaymentClicked, isPlaidPaymentClicked, isKlarnaPaymentClicked, partialPaymentsEnabled, tipAmount } = this.state;

        let paymentAmount = '';
        if (tipAmount !== '') {
            paymentAmount = amount + parseFloat(tipAmount);
        }

        if (!isPaymentOptionsLoaded) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true} />
                    </div>
                </div>
            )
        }
        else if (request === 'SERVER_ERROR') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Something went wrong</h5>
                        <MdInfo size="10em" color='#FF5252' />
                    </div>
                    {errorMessage !== '' ?
                        <p className="something-wrong-description center">{errorMessage}</p>
                        :
                        <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
            )
        }
        else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                    <div className="wrap innerSpacer center">
                        <h5 className="header3 center">Link expired</h5>
                        <MdNoEncryption size="10em" color='#008AFF' />
                    </div>
                    <p className="token-expired-description center">This payment link has expired, a new one has been sent to your email.</p>
                    <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                </div>
            )

        } else if (isCardPaymentClicked) {
            return (
                <CardCheckout
                    server={server}
                    token={token}
                    amount={paymentAmount}
                />
            )
        } else if (isKlarnaPaymentClicked) {
            return (
                <KlarnaCheckout
                    server={server}
                    token={token}
                    amount={paymentAmount}
                />
            )
        } else if (isPlaidPaymentClicked) {
            return (
                <PlaidCheckout
                    server={server}
                    token={token}
                    amount={paymentAmount}
                />
            )
        } else {
            const subTotal = amount - (vat || salesTax);
            return (
                <div className="container">
                    <form className="pure-form address-form">
                        <div className="torn-paper-container">
                            <div style={{
                                backgroundColor: '#f6f6f6',
                                paddingTop: '40px',
                                paddingBottom: '40px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                            }}>
                                <h3 className="header3" style={{ textAlign: 'center', marginLeft: '0px', fontSize: '20px', marginBottom: '25px' }}>{company || name}</h3>
                                <div className='payment-option-spacer '></div>
                                <p className="order-description">{date}</p>
                                <p className="order-description">#{reference}</p>
                                <div className='payment-option-spacer '></div>
                                <p className="items-description">
                                    {items.map((item, index) => (
                                        <this._displayItem
                                            key={index}
                                            description={item.name}
                                            qty={item.qty}
                                            price={Number(item.price)}
                                        />
                                    ))}
                                </p>
                                <div className='payment-option-spacer'></div>
                                <p className="order-description">
                                    {(vat > 0 || salesTax > 0) ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Subtotal</span>
                                            <span style={{ textAlign: 'right' }}>{this._currencySymbol(currency)}{subTotal.toFixed(2)}</span>
                                        </div>
                                    ) : null}
                                </p>
                                <p className="order-description">
                                    {vat > 0 ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>VAT</span>
                                            <span style={{ textAlign: 'right' }}>{this._currencySymbol(currency)}{vat.toFixed(2)}</span>
                                        </div>
                                    ) : null}
                                </p>
                                <p className="order-description">
                                    {salesTax > 0 ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Sales tax</span>
                                            <span style={{ textAlign: 'right' }}>{this._currencySymbol(currency)}{salesTax.toFixed(2)}</span>
                                        </div>
                                    ) : null}
                                </p>
                                <div className='payment-option-spacer '></div>
                                <p className="total-description">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <text>Total</text>
                                        <span className="total-description" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                            {this._currencySymbol(currency)}{amount.toFixed(2)}
                                        </span>
                                    </div>
                                </p>
                            </div>
                        </div>

                        {partialPaymentsEnabled !== true && (
                            <div className="tip-amount">
                                <div className='payment-option-spacer '></div>
                                <hr></hr>
                                <div className='payment-option-spacer '></div>
                                <p className="tip-header">Add a tip?</p>
                                <div className="tip-input-control-wrapper">
                                    <input
                                        type="tip"
                                        inputMode="numeric"
                                        className="input-control"
                                        value={tipAmount}
                                        onChange={this.handleAmountChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.target.blur();
                                            }
                                        }}
                                        placeholder="Enter amount" />
                                </div>
                            </div>
                        )}
                        {tipAmount !== '' && (
                            <p className="tip-description">
                                {this._currencySymbol(currency)}{(amount + parseFloat(tipAmount)).toFixed(2)} is the total amount you will pay
                            </p>
                        )}



                        <div style={{ marginTop: '10px', }}>
                            <div className='payment-option-spacer '></div>
                            <hr></hr>
                            <div className='payment-option-spacer '></div>
                            <p className="fraud-description">
                                Before continuing with payment, please be aware of scams - never share personal information. By continuing, you confirm that you are comfortable making this payment.
                            </p>
                            <div className='payment-option-spacer '></div>
                            <div className='payment-option-spacer '></div>
                            {isPlaidEnabled &&
                                <button type="button" className="button-payment-option" onClick={this._onPlaidPaymentClick}>Pay by bank</button>
                            }
                            {isKlarnaEnabled &&
                                <button type="button" className="klarna-payment-option" onClick={this._onKlarnaPaymentClick}>
                                    <img src={KlarnaLogo} alt="klarna logo" width="90px" />
                                </button>
                            }
                            {isStripeEnabled &&
                                <button type="button" className="button-payment-option" onClick={this._onCardPaymentClick}>Card payment</button>
                            }
                            <hr></hr>
                            <div className='payment-option-spacer '></div>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default UniversalCheckout;
