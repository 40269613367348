import React, { useEffect, useState } from 'react';
import { MdNoEncryption, MdInfo } from 'react-icons/md';
import DotLoader from 'react-spinners/DotLoader';
import GetUome from './GetUome';
import { usePlaidLink } from 'react-plaid-link';

function usePointOfSalePlaidCheckout(baseUrl, token) {
    const [urlRequestStatus, setUrlRequestStatus] = useState(null);
    const [linkToken, setLinkToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const plaidCheckoutUrl = `${baseUrl}v1/plaid-web-payment/pis?version=${new Date()}`;

        setUrlRequestStatus('REQUEST_IN_PROGRESS');

        fetch(plaidCheckoutUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: { token }
            })
        })
            .then(res => res.json())
            .then(result => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        setUrlRequestStatus('TOKEN_EXPIRED');
                    } else {
                        setUrlRequestStatus('REQUEST_SUCCESS');
                        setLinkToken(result.message);
                    }
                } else {
                    setUrlRequestStatus('SERVER_ERROR');
                    setErrorMessage(result.message);
                }
            })
            .catch(_ => {
                setUrlRequestStatus('SERVER_ERROR');
            });

    }, [baseUrl, token, setUrlRequestStatus, setLinkToken, setErrorMessage]);

    return {
        config: {
            onSuccess: (token, metadata) => { 
                window.location.href = 'https://www.myuome.com/checkout-complete';
            },
            onExit: (err, metadata) => { 
                window.location.href = 'https://www.myuome.com/checkout-cancel';
            },
            onEvent: (eventName, metadata) => { },
            token: linkToken,
        },
        urlRequestStatus,
        errorMessage,
    };
}

const PointOfSalePlaidCheckout = (props) => {
    const { server, token } = props;
    const { config, urlRequestStatus, errorMessage } = usePointOfSalePlaidCheckout(server, token);
    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if (config && ready && urlRequestStatus === 'REQUEST_SUCCESS') {
            open();
        }
    }, [config, open, ready, urlRequestStatus]);

    if (urlRequestStatus === 'REQUEST_IN_PROGRESS') {
        return (
            <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true} />
                    </div>
                </div>
        );
    }

    if (urlRequestStatus === 'TOKEN_EXPIRED') {
        return (
            <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Link expired</h5>
                            <MdNoEncryption size="10em" color='#008AFF' />
                        </div>
                        <p className="token-expired-description center">This payment link has expired, a new one has been sent to your email.</p>
                        <p className="token-expired-description center">Please check your inbox and click on the link.</p>
                        <GetUome />
                </div>
        );
    }

    if (urlRequestStatus === 'SERVER_ERROR') {
        return (
            <div className="container">
                        <div className="wrap innerSpacer center">
                            <h5 className="header3 center">Something went wrong</h5>
                            <MdInfo size="10em" color='#FF5252' />
                        </div>
                        {errorMessage !== "" ?
                            <p className="something-wrong-description center">{errorMessage}</p>
                            :
                            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
                </div>
        );
    }

    return <></>;
}

export default PointOfSalePlaidCheckout;