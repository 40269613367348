import React, { Component } from 'react';

import './App.css';
import ForgotPasswordForm from './components/ForgetPasswordForm';
import ChangeAddressForm from './components/ChangeAddressForm';
import EmailChangedForm from './components/EmailChangedForm';
import EmailVerifiedForm from './components/EmailVerifiedForm';
import CardCheckout from './components/CardCheckout';
import KlarnaCheckout from './components/KlarnaCheckout';
import ApproveQuoteForm from './components/ApproveQuoteForm';
import PlaidCheckout from './components/PlaidCheckout';
import PointOfSalePlaidCheckout from './components/PointOfSalePlaidCheckout';
import CreateAccountForm from './components/CreateAccountForm';
import YourPlanForm from './components/YourPlanForm';
import LoginForm from './components/LoginForm';
import PointOfSaleUniversalCheckout from './components/PointOfSaleUniversalCheckout';
import UniversalCheckout from './components/UniversalCheckout';

class App extends Component {
  constructor(props) {
    super(props);

    const server = process.env.REACT_APP_UOME_DOMAIN;
    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

    let token = ''

    const urlParams = new URL(window.location.href);

    let idPwd = urlParams.searchParams.get("token")

    const path = window.location.pathname;

    if (idPwd) {
      token = idPwd
    }

    this.state = {
      token,
      server,
      stripeKey,
      path,
    }
  }

  render() {
    const { server, token, path, stripeKey } = this.state

    if (path === '/postAddress') {
      return (
        <ChangeAddressForm
          server={server}
          token={token}
        />
      )
    } else if (path === '/changeEmail') {
      return (
        <EmailChangedForm
          server={server}
          token={token}
        />
      )
    } else if (path === '/validateEmail') {
      return (
        <EmailVerifiedForm
          server={server}
          token={token}
        />
      )
    } else if (path === '/quoteApproval') {
      return (
        <ApproveQuoteForm
          server={server}
          token={token}
        />
      )
    } else if (path === '/cardCheckout') {
      return (
        <CardCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/klarnaCheckout') {
      return (
        <KlarnaCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/plaidCheckout') {
      return (
        <PlaidCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/posCheckout') {
      return (
        <PointOfSalePlaidCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/posUniCheckout') {
      return (
        <PointOfSaleUniversalCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/pay') {
      return (
        <UniversalCheckout
          server={server}
          token={token}
        />
      )
    } else if (path === '/affiliate') {
      return (
        <CreateAccountForm
          server={server}
          token={token}
        />
      )
    } else if (path === '/yourPlan') {
      return (
        <YourPlanForm
          server={server}
          token={token}
          stripeKey={stripeKey}
        />
      )
    } else if (path === '/pwd') {
      return (
        <ForgotPasswordForm
          server={server}
          token={token}
        />
      )
    } else {
      return (
        <LoginForm
          server={server}
        />
      )
    }
  }
}

export default App;
